import axios from 'axios'

const ConfigureInterceptors = () => {
  axios.interceptors.request.use((config) => {
    config.baseURL = process.env.REACT_APP_API_URL;

    return config;
  });

  axios.interceptors.response.use(response => response.data, error => Promise.reject(error));
};

export default ConfigureInterceptors;
