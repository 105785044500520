import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape } from 'prop-types';
import Slick from 'react-slick';
import Slider from 'react-slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Modal from '../Modal';
import closeIcon from '../../assets/images/close-icon.svg';
import { modalStyles, previewSliderSettings, thumbnailSliderSettings } from './constants';

const ManualModal = ({ activeImageIndex, isOpen, onCloseModal, sliderValue, slides, onGoTo }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  useEffect(() => {

  }, []);

  const thumbnailSliderProps = { ...thumbnailSliderSettings, initialSlide: activeImageIndex };
  const previewSliderProps = { ...previewSliderSettings, initialSlide: activeImageIndex };

  const handleModalOpen = () => document.body.style.overflow = 'hidden';

  const handleModalClose = () => document.body.style.overflow = 'auto';

  const renderSlider = () =>
    slides.map(({ img }, index) => (
      <div className="slideContent" key={index}>
        <div className="slideImage">
          <img src={img} alt="" />
        </div>
      </div>
    ));

  return (
    <Modal isOpen={isOpen} style={modalStyles} onAfterOpen={handleModalOpen} onAfterClose={handleModalClose}>
      <div className="modalManualContent">
        <div className="thumbnails">
          <Slick
            {...thumbnailSliderProps}
            ref={(slick2) => setNav2(slick2)}
            asNavFor={nav1}
            focusOnSelect
          >
            {renderSlider()}
          </Slick>
        </div>

        <div className="slides">
          <Slick
            {...previewSliderProps}
            ref={(slick1) => setNav1(slick1)}
            asNavFor={nav2}
            afterChange={onGoTo}
          >
            {renderSlider()}
          </Slick>
        </div>

        <Slider
          className="slider"
          thumbClassName="sliderThumb"
          trackClassName="sliderTrack"
          max={slides.length - 1}
          value={sliderValue}
          onAfterChange={onGoTo}
        />
      </div>

      <button className="closeButton" onClick={onCloseModal}>
        <img src={closeIcon} alt="Close icon" />
      </button>
    </Modal>
  );
};

ManualModal.propTypes = {
  activeImageIndex: number,
  isOpen: bool,
  onCloseModal: func.isRequired,
  slides: arrayOf(shape({}))
};

ManualModal.defaultProps = {
  activeImageIndex: undefined,
  isOpen: false,
  slides: []
};

export default ManualModal;
