import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { LocalizationContext } from '../../utils/context';
import arrowLeft from '../../assets/images/arrow-left.svg';

const Breadcrumbs = () => {
  const strings = useContext(LocalizationContext);

  return (
    <div className="breadcrumbs">
      <NavLink to="home">
        <img src={arrowLeft} alt="" />
        {strings.paths.home}
      </NavLink>
    </div>
  );
}

export default Breadcrumbs;
