import React, { useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { LocalizationContext } from '../../utils/context';
// import { prepareWFPWidget } from '../../utils/wfp';
import PaymentDetails from '../../components/PaymentDetails';
import Breadcrumbs from '../../components/Breadcrumbs';
import CopyTo from '../../components/CopyTo';
// import logoWFP from '../../assets/images/logo-wfp.svg';
// import ccWFP from '../../assets/images/cc-wfp.svg';
import ccPrivat24 from '../../assets/images/cc-privat24.svg';
// import ccIconWhite from '../../assets/images/cc-icon-white.svg';
import ccIconBlack from '../../assets/images/cc-icon-black.svg';
import btcQR from '../../assets/images/qr-codes/btc.jpg';
import ethQR from '../../assets/images/qr-codes/eth.jpg';
import usdtQR from '../../assets/images/qr-codes/usdt.jpg';

const Donate = () => {
  const strings = useContext(LocalizationContext);

  // const openPaymentForm = async () => {
  //   const wfp = await prepareWFPWidget(strings);
  //   wfp();
  // };

  return (
    <section className="hero donate">
        <Breadcrumbs />

        <div className="container">
          <h3 className="h h2 uppercase donate--title">{strings.supportWithMoney}</h3>

          <Tabs>
            <TabList className="donate--tabList">
              <Tab className="donate--tab">
                <span>{strings.payWithCard}</span>
              </Tab>
              <Tab className="donate--tab">
                <span>{strings.transfersInUkraine}</span>
              </Tab>
              <Tab className="donate--tab">
                <span>{strings.transfersFromAbroad}</span>
              </Tab>

              {/* TODO: Paypal should be commented until account is created */}
              {/*<Tab className="donate--tab">*/}
                {/*<span>Paypal</span>*/}
              {/*</Tab>*/}

              <Tab className="donate--tab">
                <span>{strings.crypto}</span>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="donate--tabPanel donate--tabPanel__1">
                <div className="donate--payByCard">
                  {/*<div className="donate--wfpWrapper">*/}
                  {/*  <div className="wfpImage">*/}
                  {/*    <img className="donate--payByCardIcon" src={ccWFP} alt="" />*/}
                  {/*  </div>*/}

                  {/*  <div>*/}
                  {/*    <div className="donate--safeTransfer">*/}
                  {/*      <img src={logoWFP} alt="" />*/}
                  {/*      <span>{strings.safeTransfer}</span>*/}
                  {/*    </div>*/}
                  {/*    <button*/}
                  {/*      className="button button--primary button--wfp"*/}
                  {/*      onClick={openPaymentForm}*/}
                  {/*      rel="noreferrer"*/}
                  {/*    >*/}
                  {/*      <img src={ccIconWhite} alt=""/>*/}
                  {/*      <span>{strings.sendMoney}*</span>*/}
                  {/*    </button>*/}

                  {/*    <p className="wfpDisclaimer">{strings.wfpDisclaimer1}<br/>{strings.wfpDisclaimer2}</p>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="donate--privat24Wrapper">
                    <div className="privat24Image">
                      <img className="donate--payByCardIcon" src={ccPrivat24} alt="" />
                    </div>

                    <div>
                      <p>{strings.accountInPrivat}</p>
                      <a
                        className="button button--green button--privat24"
                        href="https://next.privat24.ua/payments/form/%7B%22token%22%3A%22809d34a2-da3e-42cf-870d-8b3b0e681b08%22%7D"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ccIconBlack} alt="" />
                        {strings.privat24}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="donate--tabPanel donate--tabPanel__2">
                <div className="donate--withinUkraine">
                  <div>
                    <h4 className="h h4 uppercase kredo">{strings.kredoBank}</h4>

                    <div className="donate--infoLine mb-2">
                      <p>{strings.recipient}</p>
                      <CopyTo value={`${strings.cfUnited} «${strings.united}»`} />
                    </div>
                    <div className="donate--infoLine mb-1">
                      <span>{strings.edrpou}</span>
                      <CopyTo value="44731135" />
                    </div>
                    <div className="donate--infoLine mb-1">
                      <span>{strings.mfo}</span>
                      <CopyTo value="325365" />
                    </div>
                    <div className="donate--infoLine mb-1">
                      <span>{strings.account}</span>
                      <CopyTo value="UA443253650000000260020036949" />
                    </div>
                    <div className="donate--infoLine">
                      <span>{strings.purposeOfPayment1}</span>
                      <CopyTo value={strings.purposeOfPayment2} />
                    </div>
                  </div>

                  <div>
                    <h4 className="h h4 uppercase privat">{strings.privatBank}</h4>

                    <div className="donate--infoLine mb-2">
                      <p>{strings.recipient}</p>
                      <CopyTo value={`${strings.cfUnited} «${strings.united}»`} />
                    </div>
                    <div className="donate--infoLine mb-1">
                      <span>{strings.edrpou}</span>
                      <CopyTo value="44731135" />
                    </div>
                    <div className="donate--infoLine mb-1">
                      <span>{strings.mfo}</span>
                      <CopyTo value="305299" />
                    </div>
                    <div className="donate--infoLine mb-1">
                      <span>{strings.account}</span>
                      <CopyTo value="UA703052990000026005015500344" />
                    </div>
                    <div className="donate--infoLine">
                      <span>{strings.purposeOfPayment1}</span>
                      <CopyTo value={strings.purposeOfPayment2} />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="donate--tabPanel donate--tabPanel__3">
                <div className="donate--transfersFromAbroad">
                  <PaymentDetails currency="usd" />
                  <PaymentDetails currency="euro" />
                </div>
              </div>
            </TabPanel>

            {/* TODO: Paypal should be commented until account is created */}
            {/*<TabPanel>*/}
              {/*<div className="donate--tabPanel">*/}
                {/*<div className="donate--paypal">*/}
                  {/*<img src={paypalIcon} alt="Paypal" />*/}
                  {/*<div>*/}
                    {/*<p>{strings.user}</p>*/}
                    {/*<CopyTo value="paypal@united.if.ua" />*/}
                    {/*<button className="button button--primary" onClick={handlePaypalPayment}>PayPal</button>*/}
                  {/*</div>*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</TabPanel>*/}

            <TabPanel>
              <div className="donate--tabPanel donate--tabPanel__5">
                <div className="donate--crypto">
                  <div>
                    <img src={ethQR} alt="Bitcoin QR code" />
                    <p>Bitcoin</p>
                    <CopyTo value="1NN6abtHsShB8ZBbEdhoLa2ihvGxunoAFE" />
                  </div>
                  <div>
                    <img src={btcQR} alt="Ethereum QR code" />
                    <p>ETH (ERC20)</p>
                    <CopyTo value="0x8afa066c52392ef24ee93eac46a2330c4e50b637" />
                  </div>
                  <div>
                    <img src={usdtQR} alt="USDT QR code" />
                    <p>USDT (TRC20)</p>
                    <CopyTo value="TH3gVKDZae4v8SHuCJEkonUP8YGKpZh5eB" />
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>
  );
}

export default Donate;
