export const modalStyles = {
  overlay: {
    zIndex: 12,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'white'
  },
  content: {
    height: '100%',
    border: 0,
    inset: 0,
    padding: 0,
    borderRadius: 0
  }
};

export const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export const previewSliderSettings = {
  ...settings,
  infinite: true,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true
      }
    }
  ]
};

export const thumbnailSliderSettings = {
  ...settings,
  arrow: false,
  vertical: true,
  slidesToShow: 5
};