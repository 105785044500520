export const USD = 'usd';

export const SWIFT_USD = [
  {
    label: 'Beneficiary',
    value: 'CO «CF «UNITED»'
  },
  {
    label: 'IBAN',
    value: 'UA243253650000000260080036950'
  },
  {
    label: 'Beneficiary\'s bank',
    value: 'JSC KREDOBANK, Lviv, Ukraine',
    swift: 'WUCBUA2X'
  },
  {
    label: 'Correspondent bank',
    value: 'BANK OF NEW YORK MELLON, New York, USA',
    swift: 'IRVTUS3N'
  }
];

export const SWIFT_EURO = [
  {
    label: 'Beneficiary',
    value: 'CO «CF «UNITED»'
  },
  {
    label: 'IBAN',
    value: 'UA603253650000000260020036952'
  },
  {
    label: 'Beneficiary\'s bank',
    value: 'JSC KREDOBANK, Lviv, Ukraine',
    swift: 'WUCBUA2X'
  },
  {
    label: 'Correspondent bank',
    value: 'KBC BANK NV, Brussels, Belgium',
    isRecommended: true,
    swift: 'KREDBEBB'
  },
  {
    label: 'or Correspondent bank',
    value: 'COMMERZANK AG, Frankfurt, Germany',
    swift: 'COBADEFF'
  },
  {
    label: 'or Correspondent bank',
    value: 'UNICREDIT S.P.A., Milano, Italy',
    swift: 'UNCRITMM'
  },
  {
    label: 'or Correspondent bank',
    value: 'RAIFFEISEN BANK INTERNATIONAL AG, Vienna, Austria',
    swift: 'RZBAATWW'
  }
];