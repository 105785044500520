import React, { useContext, useEffect, useState } from 'react';
import { oneOf } from 'prop-types';
import cx from 'classnames';

import CopyTo from '../CopyTo';
import flagUS from '../../assets/images/flag-us.svg';
import flagEU from '../../assets/images/flag-eu.svg';
import { LocalizationContext } from '../../utils/context';
import { USD, SWIFT_EURO, SWIFT_USD } from './constants';

const PaymentDetails = ({ currency }) => {
  const [details, setDetails] = useState(null);

  const strings = useContext(LocalizationContext);

  const isUSD = currency === USD;

  useEffect(() => setDetails(isUSD ? SWIFT_USD: SWIFT_EURO ), [isUSD]);

  if (!details) {
    return null;
  }

  return (
    <div className={cx('paymentDetails', { isEuro: !isUSD })}>
      <h5 className="h h5">
        <img src={isUSD ? flagUS : flagEU} alt={`${isUSD ? 'US' : 'EU'} flag`} />
        {isUSD ? 'USD (US Dollars)' : 'EUR (Euro)'}
      </h5>

      {details.map(({ isRecommended, label, swift, value }, i) => (
        <div className={cx({ isRecommended: isRecommended })} key={i}>
          <p>
            {label}
            {isRecommended && <span>(Recommended)</span>}
          </p>
          <CopyTo value={value} />

          {swift && (
            <>
              <p className="swift">SWIFT</p>
              <CopyTo value={swift} />
            </>
          )}

          {isRecommended && (
            <p className="isRecommended--label">
              <span>{strings.additionalCorrespondentBanks}</span>
            </p>
          )}
        </div>
      ))}
    </div>
  );
}

PaymentDetails.propTypes = {
  currency: oneOf(['usd', 'euro']).isRequired
};

export default PaymentDetails;
