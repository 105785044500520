import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { func, string } from 'prop-types';
import cx from 'classnames';

import logo from '../../assets/images/logo.svg';
import { LocalizationContext } from '../../utils/context';
import { EN, UA } from '../../utils/constants';

const Header = ({ currentLanguage, onLanguageChange }) => {
  const strings = useContext(LocalizationContext);
  const { pathname } = useLocation();

  const isDonate = pathname.includes('donate');

  return (
    <header className="header">
      <div className="header--content">
        <Link to="/" className="uppercase header--logo">
          <img src={logo} alt="" />
          <span>{strings.united}</span>
        </Link>
        <div className="header--rightContent">
          <ul className="header--languageToggler">
            <li>
              <button className={cx({ 'active': currentLanguage === UA })} onClick={() => onLanguageChange(UA)}>UA</button>
            </li>
            <li>
              <button className={cx({ 'active': currentLanguage === EN })} onClick={() => onLanguageChange(EN)}>ENG</button>
            </li>
          </ul>
          {!isDonate && <Link to="/donate" className="button button--primary button--donate">{strings.donate}</Link>}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  currentLanguage: string.isRequired,
  onLanguageChange: func.isRequired
};

export default Header;
