import React, { useContext } from 'react';
import { string } from 'prop-types';

import { LocalizationContext } from '../../utils/context';
import { IFAKS_GOAL, IFAKS_SHIPPED } from '../../utils/constants';
import progressCardImage from '../../assets/images/progressCard-image.png';

const ProgressCard = ({ imageSrc }) => {
  const strings = useContext(LocalizationContext);

  const progress = IFAKS_SHIPPED * 100 / IFAKS_GOAL;

  return (
    <div className="progressCard">
      <div className="progressCard--image">
        <img src={imageSrc || progressCardImage} alt="" />
      </div>

      <div className="progressCard--details">
        <p>{strings.ourGoal} — {IFAKS_GOAL} {strings.ifaks1}</p>
        <p>{strings.description2}</p>

        <div className="progressBar">
          <div className="progressBar--details">
            <span>{IFAKS_SHIPPED}</span>
            <span>{IFAKS_GOAL}</span>
          </div>
          <div className="progressBar--progress">
            <div className="progressBar--line" style={{ width: `${progress}%` }} />
            <div className="progressBar--circle" />
          </div>
        </div>
      </div>
    </div>
  );
}

ProgressCard.propTypes = {
  imageSrc: string
};

ProgressCard.defaultProps = {
  imageSrc: null
};

export default ProgressCard;
