export const IFAKS_GOAL = 6000;
export const IFAKS_SHIPPED = 5134;
export const FUNDS_COLLECTED = '7 808 998';

// oldboi: 166 334 + 180 000 + 265 000 + 568 000

export const UA = 'ua';
export const EN = 'en';

export const UAH = 'uah';
export const USD = 'usd';
export const EURO = 'euro';
