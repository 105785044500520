import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home';
import Donate from './pages/Donate';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
// import RequestHelp from './components/RequestHelp';

import './App.scss';
import { strings } from './utils/localization';
import { LocalizationContext } from './utils/context';
import { UA } from './utils/constants';
import { useLocalStorage } from './hooks/UseLocalStorage';
import ConfigureInterceptors from './api/configure-interceptors';

ConfigureInterceptors();

const App = () => {
  const [currentLanguage, setCurrentLanguage] = useLocalStorage('language', UA);

  strings.setLanguage(currentLanguage);

  useEffect(() => strings.setLanguage(currentLanguage), [currentLanguage]);

  const handleLanguageChange = (language) => setCurrentLanguage(language);

  return (
    <LocalizationContext.Provider value={strings}>
      <BrowserRouter>
        <div className="pageWrapper">
          <Header currentLanguage={currentLanguage} onLanguageChange={handleLanguageChange} />

          <ScrollToTop>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='donate' element={<Donate />} />
              {/*<Route path='request-help' element={<RequestHelp />}/>*/}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </ScrollToTop>

          <Footer />
        </div>
      </BrowserRouter>
    </LocalizationContext.Provider>
  );
}

export default App;
