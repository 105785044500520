import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Manual from '../../components/Manual';
import ProgressCard from '../../components/ProgressCard';
import Summary from '../../components/Summary';
import progressCardImage from '../../assets/images/home-image.jpeg';
import { LocalizationContext } from '../../utils/context';
import { IFAKS_GOAL } from '../../utils/constants';

const Home = () => {
  const strings = useContext(LocalizationContext);

  return (
    <>
      <section className="hero home">
        <div className="container">
          <div className="home--content">
            <h1 className="home--title">
              <span>{strings.cfUnited}</span>
              {strings.united}
            </h1>
            <p className="home--description">{strings.description1}</p>

            <div className="home--goal">
              <h3 className="h h3">
                {strings.ourGoal} — {IFAKS_GOAL} {strings.ifaks1}
                <br />
                {strings.description2}
              </h3>
            </div>

            <ProgressCard imageSrc={progressCardImage} />

            <Link to="/donate" className="button button--primary">{strings.donate}</Link>
            {/*<Link to="/request-help" className="button button--secondary">{strings.requestHelp}</Link>*/}

            <div className="mobile__visible">
              <Summary />
            </div>
          </div>
        </div>
      </section>

      <section className="mobile__hidden">
        <div className="container">
          <Summary />
        </div>
      </section>

      <section className="hero manual">
        <Manual />
      </section>
    </>
  );
}

export default Home;
