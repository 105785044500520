export const settings = {
  // autoplay: true,
  // autoplaySpeed: 10000,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  swipe: false,
  responsive: [
    {
      breakpoint: 961,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        infinite: true,
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '120px',
        swipe: true
      }
    },
    {
      breakpoint: 680,
      settings: {
        infinite: true,
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '50px',
        swipe: true,
        dots: false
      }
    },
    {
      breakpoint: 540,
      settings: {
        infinite: true,
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '16px',
        swipe: true,
        dots: false
      }
    },
    {
      breakpoint: 375,
      settings: {
        infinite: true,
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '16px',
        swipe: true,
        dots: false
      }
    }
  ]
};
