import React, { useContext, useState, useEffect } from 'react';
import { string } from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { LocalizationContext } from '../../utils/context';
import iconCopy from '../../assets/images/icon-copy.svg';

const CopyTo = ({ value }) => {
  const strings = useContext(LocalizationContext);

  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isCopied) {
        setCopied(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <div className="copyToClipboard">
      <span className="copyToClipboard--value">{value}</span>

      <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
        <div className="copyToClipboard--iconWrapper">
          <img src={iconCopy} alt=""/>
          <span className={`copyToClipboard--status ${!isCopied ? 'hide' : ''}`}>{strings.copied}</span>
        </div>
      </CopyToClipboard>
    </div>
  );
}

CopyTo.propTypes = {
  value: string.isRequired
};

export default CopyTo;

