import React, { useContext } from 'react';

import { LocalizationContext } from '../../utils/context';
import { FUNDS_COLLECTED, IFAKS_SHIPPED } from '../../utils/constants';
import iconIFAKs from '../../assets/images/icon-ifaks.svg';
import iconFunds from '../../assets/images/icon-funds.svg';

const Summary = () => {
  const strings = useContext(LocalizationContext);

  return (
    <div className="summary">
      <div>
        <img src={iconFunds} alt="" />
        <div>
          <h3>{FUNDS_COLLECTED} {strings.uah}</h3>
          <p>{strings.moneyCollected}</p>
        </div>
      </div>

      <div>
        <img src={iconIFAKs} alt="" />
        <div>
          <h3>{IFAKS_SHIPPED} {strings.ifaks}</h3>
          <p>{strings.deliveredToFront}</p>
        </div>
      </div>

      <div>
        <a href="https://www.facebook.com/charity.fund.united" target="_blank" rel="noreferrer">
          {strings.reportsOnFb}
        </a>
      </div>
    </div>
  );
};

export default Summary;
