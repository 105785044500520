import React from 'react';

import image1 from '../../assets/images/manual/jpg/1.jpg';
import image2 from '../../assets/images/manual/jpg/2.jpg';
import image3 from '../../assets/images/manual/jpg/3.jpg';
import image4 from '../../assets/images/manual/jpg/4.jpg';
import image5 from '../../assets/images/manual/jpg/5.jpg';
import image6 from '../../assets/images/manual/jpg/6.jpg';
import image7 from '../../assets/images/manual/jpg/7.jpg';
import image8 from '../../assets/images/manual/jpg/8.jpg';
import image9 from '../../assets/images/manual/jpg/9.jpg';
import image10 from '../../assets/images/manual/jpg/10.jpg';
import image11 from '../../assets/images/manual/jpg/11.jpg';
import image12 from '../../assets/images/manual/jpg/12.jpg';
import image13 from '../../assets/images/manual/jpg/13.jpg';
import image14 from '../../assets/images/manual/jpg/14.jpg';
import image15 from '../../assets/images/manual/jpg/15.jpg';
import image16 from '../../assets/images/manual/jpg/16.jpg';
import image17 from '../../assets/images/manual/jpg/17.jpg';
import image18 from '../../assets/images/manual/jpg/18.jpg';
import image19 from '../../assets/images/manual/jpg/19.jpg';
import image20 from '../../assets/images/manual/jpg/20.jpg';
import image21 from '../../assets/images/manual/jpg/21.jpg';
import image22 from '../../assets/images/manual/jpg/22.jpg';
import image23 from '../../assets/images/manual/jpg/23.jpg';
import image24 from '../../assets/images/manual/jpg/24.jpg';

import Manual from './Manual';

const ManualContainer = () => {
  const slides = [
    {
      img: image1
    },
    {
      img: image2
    },
    {
      img: image3
    },
    {
      img: image4
    },
    {
      img: image5
    },
    {
      img: image6
    },
    {
      img: image7
    },
    {
      img: image8
    },
    {
      img: image9
    },
    {
      img: image10
    },
    {
      img: image11
    },
    {
      img: image12
    },
    {
      img: image13
    },
    {
      img: image14
    },
    {
      img: image15
    },
    {
      img: image16
    },
    {
      img: image17
    },
    {
      img: image18
    },
    {
      img: image19
    },
    {
      img: image20
    },
    {
      img: image21
    },
    {
      img: image22
    },
    {
      img: image23
    },
    {
      img: image24
    }
  ];

  return <Manual slides={slides} />;
};

export default ManualContainer;
