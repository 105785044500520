import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .6)',
    zIndex: '99',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    border: 0,
    padding: 0,
    inset: 'auto',
    margin: '0 16px',
    position: 'relative',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    maxHeight: '95vh',
    maxWidth: '640px',
    overflow: 'hidden'
  }
}

const Modal = (props) => <ReactModal style={modalStyles} {...props}>{props.children}</ReactModal>;

Modal.propTypes = {
  children: oneOfType([arrayOf(node), node])
};

Modal.defaultProps = {
  children: undefined
};

export default Modal;
