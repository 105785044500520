import React, { useContext, useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import Slick from 'react-slick';
import Slider from 'react-slider';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

import manual from '../../assets/files/manual.pdf';
import ManualModal from '../ManualModal';
import { settings } from './constants';
import { LocalizationContext } from '../../utils/context';

const Manual = ({ slides }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [slick, setSlick] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(undefined);
  const strings = useContext(LocalizationContext);

  const handleOpenModal = (index) => {
    setActiveImageIndex(index);
    setIsOpen(true);
  };

  const handleCloseModal = () => setIsOpen(false);

  const handleOpenPDF = () => window.open(manual, '_blank', 'noopener, noreferrer');

  const handleGoTo = (value) => {
    slick.slickGoTo(value);
    setSliderValue(value);
  };

  const handleSliderChange = (value) => setSliderValue(value);

  return (
    <div className="container">
      <h2 className="h h2 uppercase manual--title">{strings.manual.title}</h2>
      <h3 className="h h3 uppercase manual--subtitle">
        {strings.manual.subtitle}
        <span>{strings.manual.subtitleSuffix}</span>
      </h3>

      <Slick {...settings} ref={(slick) => setSlick(slick)} afterChange={handleSliderChange}>
        {slides.map(({ img }, index) => (
          <div className="slideContent" key={index} onClick={() => handleOpenModal(index)}>
            <div className="slideImage">
              <LazyLoadImage src={img} alt="" />
            </div>
            <div className="hover" onClick={() => handleOpenModal(index)} />
          </div>
        ))}
      </Slick>

      <Slider
        className="slider"
        thumbClassName="sliderThumb"
        trackClassName="sliderTrack"
        max={slides.length - 1}
        value={sliderValue}
        onAfterChange={handleGoTo}
      />

      <div className="ctaButtonWrapper">
        <button className="button button--primary" type="button" onClick={handleOpenPDF}>
          {strings.manual.openInPDF}
        </button>
      </div>

      <ManualModal
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        activeImageIndex={activeImageIndex}
        slides={slides}
        onGoTo={handleGoTo}
        sliderValue={sliderValue}
      />
    </div>
  );
};

Manual.propTypes = {
  slides: arrayOf(shape({})).isRequired
};

export default trackWindowScroll(Manual);
